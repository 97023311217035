import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatBubbleTime } from './chat-bubble-time';
import { ChatTimestampFormat } from './chat-timestamp-pipe';
import { HexToRgba } from './hex-to-rgba-pipe';

@NgModule({
    declarations: [ChatBubbleTime, ChatTimestampFormat, HexToRgba],
    imports: [CommonModule],
    exports: [ChatBubbleTime, ChatTimestampFormat, HexToRgba]
})

export class MainPipeModule { }
