import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { getSessionId } from './helpers';

import { environment } from '../environments/environment';

@Injectable()
export class ResetPasswordService extends RequestBase {

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        public translate: TranslateService
    ) {
        super(http);
    }

    resetPassword(user: any) {
        let locale = this.translate.currentLang;
        if (!locale) {
            locale = this.authService.currentLanguage;
        }

        const sessionId = getSessionId();
        const apiUrl = environment.baseApiUrl.toString();
        const url = apiUrl + `session/${sessionId}/password_resets`;

        let httpOptions = {
            locale: locale,
            user
        };

        return this.http.post(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
