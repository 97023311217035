import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';

@Injectable()
export class SetPasswordService extends RequestBase {

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        public translate: TranslateService
    ) {
        super(http);
    }

    setPassword(user_id?: number, token?: string, tokenType?: string,  user?: any) {
        const apiUrl = environment.baseApiUrl.toString();
        const url = apiUrl + `users/${user_id}/${tokenType}/${token}`;

        const data = {
            params: {
                locale: this.translate.currentLang
            },
            user
        };

        return this.http.post(url, data, this.options)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
