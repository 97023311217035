import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { getSessionId } from './helpers';
import { Settings } from './settings-model';

export interface SettingsResponse {
    settings: Settings;
}

@Injectable()
export class SettingsService extends RequestBase {

    constructor(
        public http: HttpClient,
        public authService: AuthService,
    ) {
        super(http);
    }


    fetchSettingsData() {
        const sessionId = getSessionId();

        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/settings.json`;


        return this.http.get(url)
            .pipe(map((res: SettingsResponse) => {
                return res;
            }))
            .pipe(catchError(this.handleError));
    }
}
