import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { AuthService } from './auth-service';
import { RequestBase } from './request-base';

import { environment } from '../environments/environment';

@Injectable()
export class ChangePasswordService extends RequestBase {

    constructor(public http: HttpClient, public authService: AuthService) {
        super(http);
    }

    changePassword(user) {

        const sessionId = this.authService.sessionId;
        const userId = this.authService.userId;
        const url = environment.baseApiUrl + `session/${sessionId}/users/${userId}.json`;

        const data = {
            cs: this.authService.checksum,
            user
        };

        return this.http.put(url, data, this.options)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
