import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { environment } from '../environments/environment';
import { MarkTaskDoneModel } from '../components/mark-task-done/mark-task-done.model';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MarkTaskDoneService extends RequestBase {

  constructor(
    public http: HttpClient,
    public authService: AuthService) {
    super(http);
  }

  markTaskDone(data: MarkTaskDoneModel) {

    const sessionId = this.authService.sessionId;
    const apiUrl = environment.baseApiUrl.toString();
    const taskId = data.task_id;
    const id = data.id;
    const url = apiUrl + `session/${sessionId}/tasks/${taskId}/task_jobs/${id}.json`;

    const httpOptions = {
      done_date:      data.done_date,
      user_action:    data.action,
      message:        data.message,
      cs:             this.authService.checksum,
    };

    return this.http.put(url, httpOptions)
      .pipe(map(res => res))
      .pipe(catchError(this.handleError));
  }
}
