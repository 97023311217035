import { Injectable } from '@angular/core';

@Injectable()
export class RouterService {

    prevUrl: string;

    setPrevUrl(url: string) {
        this.prevUrl = url;
    }

    getPrevUrl() {
        return this.prevUrl;
    }

    constructor() {}
}
