import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';

import { Link } from '../components/link/link.model';

export interface CustomerLinksResponse {
    links: Link[];
}

@Injectable()
export class CustomerLinksService extends RequestBase {
    constructor(
        public http: HttpClient,
        public authService: AuthService
    ) {
        super(http);
    }

    fetchCustomerLinks(fetchHistory: boolean) {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/links.json`;

        const httpOptions = {
            params: {
                cs: this.authService.checksum,
                show_history: fetchHistory.toString(),
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map((res: CustomerLinksResponse) => res))
            .pipe(catchError(this.handleError));
    }
}
