import { Chat } from '../pages/chat/chat.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ChatService extends RequestBase {
    constructor(
        public http: HttpClient,
        public authService: AuthService) {
            super(http);
        }

    create(message: string, id: number, type: number, personId: number = null, linkOrTask: string): Observable<any> {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/chats.json`;

        let chat = {};
        if (linkOrTask === 'task') {
            chat = {
                message_text: message,
                participant_person_ids: [personId],
                visibility_type_id: type,
                task_id: id
            };
        } else if (linkOrTask === 'link') {
            chat = {
                message_text: message,
                participant_person_ids: [personId],
                visibility_type_id: type,
                link_id: id
            };
        }

        const httpOptions = {
            cs: this.authService.checksum,
            chat
        };

        return this.http.post(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

    fetch(): Observable<any> {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/chats.json`;

        const params = {
            cs: this.authService.checksum
        };

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

    fetchOneChat(id: string): Observable<any> {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const params = {
            cs: this.authService.checksum
        };
        const httpOptions = {
            params
        };
        const url = `${apiUrl}/session/${sessionId}/chats/${id}.json`;

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

    delete(chat: Chat) {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/chats/${chat.id}/participants/${this.authService.personId}`;

        const body  = {
            cs: this.authService.checksum
        };

        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body
        };

        return this.http.delete(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

    fetchChat(id: string, page: number = 1): Observable<any> {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/chats/${id}/messages.json`;

        const params = {
            cs: this.authService.checksum,
            page: page.toString()
        };

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }


    sendChat(id: string, message: string) {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/chats/${id}/messages.json`;
        const httpOptions = {
            message_text:   message,
            cs:             this.authService.checksum,
        };

        return this.http.post(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
