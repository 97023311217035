import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';

@Injectable()
export class LoginWithTokenService extends RequestBase {

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        public translate: TranslateService
    ) {
        super(http);
    }

    loginWithToken(user_id: number, token: string, tokenType: string, locale: string) {
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `users/${user_id}/${tokenType}/${token}`;

        const httpOptions = {
            params: {
                locale: locale
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
