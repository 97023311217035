import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Task } from '../components/task/task.model';
import { TaskJobsTask } from '../components/task/task-jobs-task.model';
import { Link } from '../components/link/link.model';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class DateHelperService {

    constructor(public translate: TranslateService) {
    }

    formatDateTime(startMoment: string, startDescription: string, endMoment: string, endDescription: string, status: string, ongoing: boolean, bundleRepetitions: boolean, bundleStartMoment: string, bundleEndMoment: string, isTaskJob: boolean = false) {

        // i18n strings
        let from;
        let during;
        let at;

        // fetch i18n
        this.translate.get('task.from').subscribe(
            value => from = value
        );
        this.translate.get('task.during').subscribe(
            value => during = value
        );
        this.translate.get('task.at').subscribe(
            value => at = value
        );

        let startDate = startMoment;
        let endDate = endMoment;

        if (bundleRepetitions) {
            if (!(status === 'open' || status === 'in_progress')) {
                // if the first task of the bundle is not open, let's use the start moment from the bundle
                startDate = bundleStartMoment;
            }
            if (bundleEndMoment != null) {
                endDate = bundleEndMoment;
            }
        }

        let startText = moment(startDate).format('L');
        let startTime = moment(startDate).format('LT');
        let startWeekday = moment(startDate).format('ddd');

        let endText = '';
        let endTime = '';

        if (endDate != null) {
            endText = moment(endDate).format('L');
            endTime = moment(endDate).format('LT');
            if (endTime != '00.00') {
                endText += ' ' + endTime;
            }
        }
        let formattedDate = '';

        if (ongoing) {
            if (endDate != null) {
                if (moment(endDate).diff(moment(startDate), 'days') > 0)
                    formattedDate = `${during} ${startText} ${startTime} - ${endText}`;
                else if (moment(endDate).diff(moment(startDate), 'hours') > 0)
                    formattedDate = `${during} ${startText} ${startTime}-${endTime}`;
            } else {
                formattedDate = `${from} ${startText}`;
            }
        } else {
            if (bundleRepetitions) {
                if (isTaskJob || bundleStartMoment == null)
                    formattedDate += startWeekday + ' ';

                formattedDate += startText;

                if (moment(endDate).diff(moment(startDate), 'days') > 0) {
                    formattedDate += `-${moment(endDate).format('L')}`;
                }
                formattedDate += ` ${at} ${startTime}`;
                if (endDate != null) {
                    formattedDate += `-${endTime}`;
                }
            } else {
                formattedDate += startWeekday + ' ';
                formattedDate += startText + ' ';

                if (endDate != null) {
                    if (moment(endDate).diff(moment(startDate), 'days') === 0) {
                        // If end day is same as start day, don't show end day, only show time.
                        formattedDate = `${formattedDate} ${at} ${startTime}-${endTime}`;
                    } else {
                        // Else show the ending day also with weekday and time.
                        const endWeekday = moment(endDate).format('ddd');
                        formattedDate = `${formattedDate} - ${endWeekday} ${endText}`;
                    }
                } else {
                    if (startTime != '00.00') {
                        formattedDate = `${formattedDate} ${at} ${startTime}`;
                    }
                }
            }
        }

        if ((startDescription?.length > 0) || (endDescription?.length > 0)) {
            formattedDate += '\n';
            if (startDescription?.length > 0) {
                formattedDate += startDescription;
                if (endDescription?.length > 0)
                    formattedDate += ', ';
            }
            if (endDescription?.length > 0)
                formattedDate += endDescription;
        }

        return formattedDate;
    }

    formatDate(startDate: string, endDate: string) {

        // i18n strings
        let started;

        // fetch i18n
        this.translate.get('link.started').subscribe(
            value => started = value
        );

        let startText = moment(startDate).format('L');
        let endText = '';

        if (endDate != null) {
            endText = moment(endDate).format('L');
        }

        if (endDate != null) {
            return `${startText} - ${endText}`;
        } else {
            return `${started} ${startText}`;
        }
    }

    formatTaskDate(task: Task) {
        if (!task) {
            return '';
        }
        return this.formatDateTime(task.start_moment, task.start_description, task.end_moment, task.end_description, task.status, task.ongoing, task.bundle_repetitions, task.bundle_start_moment, task.bundle_end_moment);
    }

    formatTaskJobDate(task: Task, taskJob: TaskJobsTask) {
        if (!task || !taskJob) {
            return '';
        }
        return this.formatDateTime(taskJob.start_moment, task.start_description, taskJob.end_moment, task.end_description, task.status, task.ongoing, task.bundle_repetitions, taskJob.start_moment, taskJob.end_moment, true);
    }

    formatLinkDate(link: Link) {
        if (!link) {
            return '';
        }
        return this.formatDate(link.start_date, link.end_date);
    }
}
