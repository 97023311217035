import { Task } from '../components/task/task.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { map, catchError } from 'rxjs/operators';
import { getSessionId } from './helpers'

@Injectable()
export class TaskService extends RequestBase {

    public taskCache: Task[] = [];

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        public translate: TranslateService
    ) {
        super(http);
    }

    fetchTask(id: number, viewOwnTasks?: boolean, tasksType?: string) {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/${tasksType ? tasksType : 'tasks'}/${id}.json`;

        let params = {
            cs: this.authService.checksum
        };

        if (viewOwnTasks) {
            params = Object.assign(params, { own_tasks: viewOwnTasks});
        }

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

    fetchPublicTask(id: number) {
        let locale = this.translate.currentLang;
        if (!locale) {
            locale = this.authService.currentLanguage;
        }

        const sessionId = getSessionId()
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/public_tasks/${id}.json`;

        const httpOptions = {
            params: {
                locale: locale
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
