import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestBase } from './request-base';
import { appVersion, buildNumber } from '../app/globals';
import { environment } from '../environments/environment';
import { Service } from './service-model';
import { AuthResponse } from './auth-model';
import { Storage } from '@ionic/storage';
import { Organization } from './organization-model';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { getSessionId } from './helpers';
import { CryptoJsService } from './crypto-js-service';

const AUTH_SERVICE_PROPERTIES = [
    'sessionId', 'checksum', 'userId', 'personId', 'subscribeKey', 'privateChannel',
    'channels', 'organizations', 'organization', 'email', 'lastUsedLang', 'roles', 'publishKey',
    'passedTasks', 'upcomingTasks', 'task_type', 'cacheTs'
];

export interface SaveVolunteeringDataResponseInterface {
    'id': number;
    'first_name': string;
    'last_name': string;
    'email': string;
    'phone': string;
    'service_id': number;
    'service_name': string;
    'organization_name': string;
    'notification': string;
}

@Injectable()
export class AuthService extends RequestBase {

    public sessionId: any;
    public checksum: any;
    public privateChannel: any;
    public subscribeKey: any;
    public publishKey: any;
    public services: any;
    public roles: any;

    public email: string;
    public userId: any;
    public personId: any;
    public userUuid: any;

    public channels: Array<string> = [];
    public organizations: Organization[];
    public organization: Organization;
    public deviceToken: string;
    public lastUsedLang: string;
    public currentLanguage: string;

    constructor(
        private translate: TranslateService,
        private router: Router,
        public http: HttpClient,
        private storage: Storage,
        private cryptoJsService: CryptoJsService
    ) {
        super(http);
    }

    /**
     * A method which fetches saved credentials from device.
     */
    async fetchSavedCredentials() {
        await this.storage.ready();
        return await Promise.all(AUTH_SERVICE_PROPERTIES.map(async (property: string) => {
            this[property] = await this.storage.get(property);
        })).then(() => true);
    }

    clearSavedCredentials() {
        AUTH_SERVICE_PROPERTIES.forEach((property: string) => this.storage.set(property, null));
    }

    getLastUsedLanguage() {
        return this.storage.ready()
        .then( async () => await this.storage.get('lastUsedLang'));
    }

    setLastUsedLanguage(lang: string) {
        this.lastUsedLang = lang;
        return this.storage.ready()
            .then(() =>
                this.storage.set('lastUsedLang', lang)
            );
    }

    storeOrganization(organization: Organization) {
        this.storage.set('organization', organization);
        this.organization = organization;
    }

    storeOrganizations(data) {
        if (data.auth_status === 'ask_for_organization') {
            this.organizations = data.organizations;
            this.storage.set('organizations', data.organizations);
        }
    }

    login({email, password, organizationId, deviceInfo}) {
        this.storage.clear();
        this.organizations = null;

        const url = environment.authUrl;
        const creds = {
            email,
            password,
            organization_id: organizationId,
            device_token: this.deviceToken,
            device_uuid: deviceInfo?.uuid,
            device_platform: deviceInfo?.platform,
            device_os_version: deviceInfo?.osVersion,
            device_model: deviceInfo?.model,
            device_os: deviceInfo?.device_os,
            device_manufacturer: deviceInfo?.manufacturer,
            app_version: appVersion,
            app_build: buildNumber,
            locale: this.translate.currentLang,
        };

        return this.http.post(url, creds, this.options)
            .pipe(map((res: AuthResponse) => {
                this.storeOrganization(res.organizations[0]);
                this.storeOrganizations(res);
                const lng = res.api_session.locale;
                moment.locale(lng);
                this.translate.setDefaultLang(lng);
                this.translate.use(lng);
                this.setLastUsedLanguage(lng);
                const sessionData = res.api_session;
                this.sessionId = sessionData.api_key;
                this.checksum = sessionData.checksum;
                this.email = email;
                this.userId = sessionData.user_id;
                this.personId = sessionData.person_id;
                this.userUuid = sessionData.user_uuid;
                this.roles = sessionData.roles;

                this.storage.set('sessionId', this.sessionId);
                this.storage.set('checksum', this.checksum);
                this.storage.set('roles', this.roles);

                this.services = sessionData.active_services as Service[];

                this.storage.set('email', this.email);
                this.storage.set('userId', this.userId);
                this.storage.set('personId', this.personId);

                // PubNub keys
                this.subscribeKey = this.decodeFromBase64(res.subscribe_key);
                this.publishKey = this.decodeFromBase64(res.publish_key);
                this.privateChannel = this.decodeFromBase64(res.private_channel);
                this.subscribeKey = this.cryptoJsService.encrypt(this.subscribeKey);
                this.publishKey = this.cryptoJsService.encrypt(this.publishKey);
                this.channels = res.chats.map((chat) => {
                    return this.decodeFromBase64(chat.task_channel);
                });
                this.channels.push(this.privateChannel);

                this.storage.set('subscribeKey', this.subscribeKey);
                this.storage.set('publishKey', this.publishKey);
                this.storage.set('privateChannel', this.privateChannel);
                this.storage.set('channels', this.channels);
                this.storage.set('userUuid', this.userUuid);
                return res;
            }))
            .pipe(catchError(this.handleError));
    }

    logout() {
        const sessionId = this.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/users/${this.userId}/logout.json`;
        const params = {
            cs: this.checksum,
        };

        const httpOptions = {
            params
        };

        return this.http.post(url, null, httpOptions)
            .pipe(map(res => {
                this.storage.clear();
                this.organizations = null;
                // Re-setting the last used lang after clearing storage
                this.setLastUsedLanguage(this.lastUsedLang);
                this.router.navigate(['/']); // navigate to homepage
                return res;
            }))
            .pipe(catchError(this.handleError));
    }

    // iOS 9 helper for decoding base64 strings
    decodeFromBase64(input) {
        input = input.replace(/\s/g, '');
        return atob(input);
    }

    applyForVolunteering() {
        const sessionId = getSessionId();

        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/organizations.json`;

        const params = {
            for_volunteering: 'true'
        };

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => {
                this.organizations = null;

                this.storeOrganizations({
                    auth_status: 'ask_for_organization',
                    organizations: res
                });
                return res;
            }))
            .pipe(catchError(this.handleError));
    }

    // get services for Volunteering page
    getServices(organizationId) {
        const sessionId = getSessionId();

        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/organizations/${organizationId}.json`;

        const params = {
            for_volunteering: 'true',
            locale: this.translate.currentLang
        };

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => {
                return res;
            }))
            .pipe(catchError(this.handleError));
    }

    saveVolunteeringData(applicant) {
        const sessionId = getSessionId();

        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/applicants.json`;

        const body = { applicant };
        const params = {
            locale: this.translate.currentLang
        };

        const httpOptions = {
            params
        };

        return this.http.post(url, body, httpOptions)
            .pipe(map((res: SaveVolunteeringDataResponseInterface) => {
                return res;
            }))
            .pipe(catchError(this.handleError));
    }

    async getUserData() {
        const userRoles = await this.storage.get('roles');
        const personId = await this.storage.get('personId');
        const organizationData = await this.storage.get('organization');
        return { userRoles, personId, organizationData }
    }

    async getUserRoles() {
        return await this.storage.get('roles');
    }
}
