import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { RequestBase } from './request-base';
import { getSessionId } from './helpers';
import { RegisterPublicTaskInterface } from '../pages/register-public-task/register-public-task.model';

import { AuthService } from './auth-service';

@Injectable()
export class RegisterPublicTaskService extends RequestBase {

    constructor(
        public http: HttpClient,
        public translate: TranslateService,
        public authService: AuthService
    ) {
        super(http);
    }

    savePublicTaskRegisterData(publicTasksId: number, taskJobsId: number, registration) {
        const sessionId = getSessionId();
        let locale = this.translate.currentLang;
        if (!locale) {
            locale = this.authService.currentLanguage;
        }

        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/public_tasks/${publicTasksId}/public_task_jobs/${taskJobsId}/register.json`;

        const body = { registration };
        const params = {
            locale: locale
        };

        const httpOptions = {
            params
        };

        return this.http.post(url, body, httpOptions)
            .pipe(map((res: RegisterPublicTaskInterface) => {
                return res;
            }))
            .pipe(catchError(this.handleError));
    }
}
