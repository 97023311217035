import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, NavController, NavParams } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CalendarComponentOptions } from 'ion2-calendar';

import { Events } from '../../providers/events';
import { MarkTaskDoneService } from '../../providers/mark-task-done-service';
import { ToastService } from '../../providers/toast-service';
import { DateHelperService } from '../../providers/date-helper-service';
import { OwnTasksService } from '../../providers/own-tasks-service';

import { Task } from '../task/task.model';
import { TaskJob } from '../task/task-job.model';
import { TaskJobsTask } from '../task/task-jobs-task.model';
import { MarkTaskDoneModel } from './mark-task-done.model';
import { CacheService } from '../../providers/cache-service';
import { Storage } from '@ionic/storage';

const MONTHS = {
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  fi: ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'],
  sv: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
};

const WEEKDAYS = {
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  fi: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
  sv: ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
};

const actionDone = 'done';

@Component({
  selector: 'app-page-register-for-task',
  templateUrl: 'mark-task-done.component.html',
  styleUrls: ['mark-task-done.component.scss']
})
export class MarkTaskDoneComponent {

  @Input() onClick: () => void;
  @Input() registrationUnsubscribe: () => void;
  onDismissClick = this.navParams.get('onClick');

  message: string;
  toastText: string;
  disabled = false;
  action: string;

  task: Task = this.navParams.get('task');
  job: TaskJob = this.navParams.get('job');
  taskJob: TaskJobsTask = this.navParams.get('taskJob');

  singleOrMultipleTask  = true;
  bundle: boolean = this.task?.bundle_repetitions;

  doneDate: moment.Moment;
  type: 'string';
  rangeFrom: any = new Date(this.task.start_moment);
  rangeTo: any = new Date(Date.now());

  optionsRange: CalendarComponentOptions = {
    pickMode: 'single',
    weekStart: 1,
    monthFormat: 'MMMM YYYY',
    monthPickerFormat: MONTHS.en,
    weekdays: WEEKDAYS.en,
    showMonthPicker: false,
    from: this.rangeFrom > this.rangeTo ? this.rangeTo - 24 * 60 * 60 * 1000 * 2 : this.rangeFrom, // If task start date is in the future, let's allow picking a date from 2 days ago
    to:   this.rangeTo
  };

  constructor(
    private ownTasksService: OwnTasksService,
    private cacheService: CacheService,
    private storage: Storage,
    public http: HttpClient,
    public navCtrl: NavController,
    public navParams: NavParams,
    public toastService: ToastService,
    public events: Events,
    public translate: TranslateService,
    public markTaskDoneService: MarkTaskDoneService,
    public dateHelper: DateHelperService,
    public alertCtrl: AlertController) {
    this.optionsRange.weekStart = this.translate.store.defaultLang === 'en' ? 0 : 1;
    this.optionsRange.monthPickerFormat = MONTHS[this.translate.store.defaultLang];
    this.optionsRange.weekdays = WEEKDAYS[this.translate.store.defaultLang];
  }

  dismiss() {
    this.onDismissClick();
  }

  ionViewWillEnter() {
    if (this.bundle) {
      this.singleOrMultipleTask = false;
    }
    this.action = actionDone;
    this.optionsRange.weekStart = this.translate.store.defaultLang === 'en' ? 0 : 1;
    this.optionsRange.monthPickerFormat = MONTHS[this.translate.store.defaultLang];
    this.optionsRange.weekdays = WEEKDAYS[this.translate.store.defaultLang];
  }

  submit() {
    if (this.task.ongoing) {
      if (!this.doneDate) {
        this.toastService.showToast({text: 'selectedTaskPage.choose_day'});
        return;
      }
    }

    this.onDismissClick();
    // prevents the user from clicking the button multiple times
    this.disabled = true;

    let data: MarkTaskDoneModel;

    data = {
      task_id:        this.task.id,
      action:         this.action,
      id:             this.job.id
    } as MarkTaskDoneModel;

    if (this.task.ongoing && this.doneDate) {
      data.done_date = this.doneDate.format('YYYY-MM-DD');
    }

    this.markTaskDoneService.markTaskDone(data)
      // tslint:disable-next-line:no-shadowed-variable
      .subscribe(async (data) => {
        // this.navCtrl.back();
        await this.success(data);
        if (this.cacheService.cacheUpdate) {
          await this.updateUpcomingTasks();
        }
      }, (err) => {
        // If request had error, show it
        if (err.message && err.status !== 401) {
          this.toastService.showToast({text: err.message, duration: 3000, isI18n: false});
        } else {
          // Else show generic error message
          this.toastService.showToast({text: 'errors.unknown_error'});
        }
      });
  }

  /**
   * Update upcoming tasks in local cache after submit or cancel registration.
   */
  async updateUpcomingTasks() {
    await this.ownTasksService.setOwnTasks(false);
  }

  /**
   * Called upon successfully registering to task
   * @param data the updated task data returned from server
   */
  async success(data) {
    if (data.tasks && data.tasks[0]) {
      this.task = data.tasks[0];
    }
    if (this.isTaskDone()) {
      this.events.publish('task:markedAsDone', data);

      let title: string;
      let content: string;
      this.translate.get('selectedTaskPage.thank_you').subscribe(value => title = value);
      this.translate.get('selectedTaskPage.task_marked_as_done').subscribe(value => content = value);
      const alert = await this.alertCtrl.create({
        header: title,
        message: content,
        buttons: ['OK'],
        mode: 'ios'
      });
      await alert.present();
    }
  }

  isTaskDone() {
    return this.task.status === 'done';
  }

  taskDate(task: Task) {
    return this.dateHelper.formatTaskDate(task);
  }

}
