import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { map, catchError } from 'rxjs/operators';
import { CustomerTasks } from '../components/task/customer-tasks.model';

export interface CustomerTasksResponse {
    tasks: CustomerTasks[];
}

@Injectable()
export class CustomerTasksService extends RequestBase {

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        public storage: Storage
    ) {
        super(http);
    }

    fetchCustomerTasks(fetchHistory: boolean) {

        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/customer_tasks.json`;

        const httpOptions = {
            params: {
                cs: this.authService.checksum,
                show_history: fetchHistory.toString(),
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map((res: CustomerTasksResponse) => res))
            .pipe(catchError(this.handleError));
    }

    setCustomerTasks(passedTasks) {
        return new Promise((resolve, reject) => {
            this.fetchCustomerTasks(passedTasks)
                .pipe(map(res => res))
                .subscribe( async data => {
                        await this.storage.set('cacheTs', Date.now());
                        if (!passedTasks) {
                            await this.storage.set('upcomingTasks', data.tasks || []);
                        } else {
                            await this.storage.set('passedTasks', data.tasks || []);
                        }
                        resolve({});
                    }, error => {
                        console.error('Customer tasks response error' + error);
                        reject();
                    }
                );
        })
    }
}
