import { Injectable } from '@angular/core';
import { CronJob } from 'cron';
import { Storage } from '@ionic/storage';

import { roleCustomer } from '../app/globals';
import { OwnTasksService } from './own-tasks-service';
import { CustomerTasksService } from './customer-tasks-service';

@Injectable()
export class CacheService {

    cronJob: CronJob;
    userRoles: string[];
    ownTasksUpdate: boolean;
    refreshCache: boolean;
    cacheUpdate: boolean = false;

    constructor(
        private storage: Storage,
        private ownTasksService: OwnTasksService,
        private customerTasksService: CustomerTasksService,
    ) {}

    createJob(refreshInterval, expirationTime) {
        const cronTime = `*/${refreshInterval} * * * *`;
        this.cronJob = new CronJob(cronTime, async () => {
            const now: number = Date.now();
            const cacheTs: number|undefined = await this.storage.get('cacheTs');
            if (
                !cacheTs ||
                ((now - cacheTs) >= (expirationTime * 60 * 1000))
            ) {
                if (!this.isCustomer()) {
                    await this.fetchOwnTasks(false);
                    await this.fetchOwnTasks(true);
                } else {
                    await this.fetchCustomerTasks(false);
                    await this.fetchCustomerTasks(true);
                }
            }
        });
    }

    startJob() {
        if (this.cronJob && !this.cronJob.running) {
            this.cronJob.start();
        }
    }

    stopJob() {
        if (this.cronJob && this.cronJob.running) {
            this.cronJob.stop();
        }
    }

    async fetchOwnTasks(passedTasks: boolean) {
        await this.ownTasksService.setOwnTasks(passedTasks);
    }

    async fetchCustomerTasks(passedTasks: boolean) {
        await this.customerTasksService.setCustomerTasks(passedTasks);
    }

    isCustomer() {
        return this.userRoles?.includes(roleCustomer)
    }
}
