import { map, catchError } from 'rxjs/operators';
import { Link } from '../components/link/link.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';

@Injectable()
export class LinkService extends RequestBase {

    public linkCache: Link[] = [];

    constructor(
        public http: HttpClient,
        public authService: AuthService
    ) {
        super(http);
    }

    fetchLink(id: number) {
        const sessionId = this.authService.sessionId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/links/${id}.json`;

        const httpOptions = {
            params: {
                cs: this.authService.checksum,
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
