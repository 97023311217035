import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { User } from '../pages/login-page/user.model';
import { map, catchError } from 'rxjs/operators';

export interface UserDataResponse {
    user: User;
}

@Injectable()
export class UserDataService extends RequestBase {

    public user: User;

    constructor(public http: HttpClient, public authService: AuthService) {
        super(http);
    }

    fetchUserData() {

        const sessionId = this.authService.sessionId;
        const userId = this.authService.userId;
        const url = environment.baseApiUrl + `session/${sessionId}/users/${userId}.json`;

        const httpOptions = {
            params: {
                cs: this.authService.checksum,
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map((res: UserDataResponse) => {
                this.user = res.user;
                return res.user;
            }))
            .pipe(catchError(this.handleError));
    }

    saveUserData(user: User) {

        const sessionId = this.authService.sessionId;
        const userId = this.authService.userId;
        const url = environment.baseApiUrl + `session/${sessionId}/users/${userId}.json`;

        const httpOptions = {
            cs: this.authService.checksum,
            user
        };

        return this.http.put(url, httpOptions)
            .pipe(map((res: UserDataResponse) => {
                this.user = res.user;
                return res.user;
            }))
            .pipe(catchError(this.handleError));
    }


    saveUserPicture(formData: any) {

        const sessionId = this.authService.sessionId;
        const personId = this.authService.personId;
        const url = environment.baseApiUrl + `session/${sessionId}/people/${personId}/person_photos.json?cs=${this.authService.checksum}`;

        return this.http.post(url, formData)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
