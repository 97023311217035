import { Pipe, PipeTransform } from '@angular/core';

/*
 * Converts hexadecimal values to rgba for transparent css colors.
 * Usage:
 *   value | hexToRgba:alpha
 * Example:
 *   {{ '#f06793' | hexToRgba:0.2}}
 *   formats to: rgba(240, 103, 147, 0.2)
*/
@Pipe({
    name: 'hexToRgba'
})

export class HexToRgba implements PipeTransform {

    transform(hex: string, a: number): string {

        if (!hex) { return ''; }

        // Remove trailing #
        hex   = hex.replace('#', '');
        // Parse r,g,b values
        let r: number = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
        let g: number = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
        let b: number = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
        if (a) {
           return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
        } else {
           return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
   }
}
