import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DeviceInfo, Device } from '@capacitor/device';
import { environment } from '../environments/environment';

@Injectable()
export class PlatformService {
    platform: string;
    version: string;
    service = 'apns2';
    info: DeviceInfo;

    constructor(private devicePlatform: Platform) {
        this.getPlatform();
    }

    async getPlatform() {
        this.info = await Device.getInfo();
        this.platform = this.info.platform;
        this.version = this.info.osVersion;

        if (this.platform === 'android') {
            this.service = 'gcm';
        }
    }

    isIos() {
        return this.platform === 'ios';
    }

    isNative() {
        return this.devicePlatform.is && this.devicePlatform.is('capacitor');
    }

    isProduction() {
        return environment.production;
    }

    /**
     * Check if iOS version supports WebRTC.
     * iOS version should be >=14.5
     */
    isWebRtcSupported() {
        return Number(this.version.substring(0, 4)) >= 14.5;
    }
}
