import { TranslateService } from '@ngx-translate/core';

export class ValidationService {
    static translate: TranslateService;

    static setTranslationService( translation: TranslateService) {
        ValidationService.translate = translation;
    }

    static getValidatorErrorMessage({ validatorName, fieldName }) {
        const config = {
            required: fieldName ?
                ValidationService.translate.instant('validationFields.required_field', { field: fieldName }) :
                ValidationService.translate.instant('validationFields.required'),
            invalid: fieldName ?
                ValidationService.translate.instant('validationFields.invalid_field', { field: fieldName }) :
                ValidationService.translate.instant('validationFields.invalid'),
            not_accepted: ValidationService.translate.instant('validationFields.not_accepted'),
            not_accepted_public: ValidationService.translate.instant('validationFields.not_accepted_public'),
        };

        return config[validatorName];
    }

    static commonValidator({control, regexp}) {
        if (control?.value?.trim().match(regexp)) {
            return null;
        } else {
            return { invalid: true };
        }
    }

    static emailValidator(control) {
        return ValidationService.commonValidator({
            control,
            regexp: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        });
    }

    static phoneValidator(control) {
        return ValidationService.commonValidator({
            control,
            regexp: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){5,20}(\s*)?$/,
        });
    }

    static termsValidator(control) {
        return control?.value ? null : { not_accepted: true };
    }

    static termsPublicValidator(control) {
        return control?.value ? null : { not_accepted_public: true };
    }
}
