import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'front',
    loadChildren: () => import('../pages/front-page/front.module').then(m => m.FrontModule) },
  { path: 'login',
    loadChildren: () => import('../pages/login-page/login.module').then(m => m.LoginModule) },
  { path: 'login-with-token',
    loadChildren: () => import('../pages/login-with-token/login-with-token.module').then(m => m.LoginWithTokenModule), },
  { path: 'organizations',
    loadChildren: () => import('../pages/organizations-page/organization.module').then(m => m.OrganizationModule) },
  { path: 'public-tasks',
    loadChildren: () => import('../pages/public-tasks-page/public-tasks.module').then(m => m.PublicTasksModule) },
  { path: 'register-public-task',
    loadChildren: () => import('../pages/register-public-task/register-public-task.module').then(m => m.RegisterPublicTaskModule) },
  { path: 'volunteering',
    loadChildren: () => import('../pages/volunteering/volunteering.module').then(m => m.VolunteeringModule) },
  { path: 'tabs',
    loadChildren: () => import('../pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'selected-task/:taskId',
    loadChildren: () => import('../pages/selected-task/selected-task.module').then(m => m.SelectedTaskModule) },
  { path: 'selected-link/:linkId',
    loadChildren: () => import('../pages/selected-link/selected-link.module').then(m => m.SelectedLinkModule) },
  { path: 'selected-chat',
    loadChildren: () => import('../pages/selected-chat/selected-chat.module').then(m => m.SelectedChatModule) },
  { path: 'video-call',
    loadChildren: () => import('../pages/video/video.module').then(m => m.VideoModule) },
  { path: 'contact-details',
    loadChildren: () => import('../pages/contact-details/contact-details.module').then(m => m.ContactDetailsModule) },
  { path: 'user-settings',
    loadChildren: () => import('../pages/user-settings/user-settings.module').then(m => m.UserSettingsModule) },
  { path: 'change-password',
    loadChildren: () => import('../pages/change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'set-password',
    loadChildren: () => import('../pages/set-password/set-password.module').then(m => m.SetPasswordModule) },
  { path: 'reset-password',
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'open-task-id/:taskId',
    loadChildren: () => import('../pages/open-task-id/open-task-id.module').then( m => m.OpenTaskIdPageModule) },

  { path: '', redirectTo: '/front', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
