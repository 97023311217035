import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { FilterService } from './filter-service';
import { map, catchError } from 'rxjs/operators';
import { Task } from '../components/task/task.model';

export interface FetchTasksResponseInterface {
    page: number;
    page_count: number;
    tasks: Task[];
}

@Injectable()
export class OpenTasksService extends RequestBase {

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        public filterService: FilterService
    ) {
        super(http);
    }

    fetchOpenTasks(page: number) {
        const { filter, taskType } = this.filterService;
        const { sessionId, checksum } = this.authService;

        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/tasks.json`;

        let params = {
            cs: checksum,
            page: page.toString(),
            task_types: taskType.toString()
        };

        if (filter != null) {
            params = Object.assign(params, filter);
        }

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

}
