import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './auth-service';
import { RequestBase } from './request-base';

import { Filter } from './filter-model';
import { Service } from './service-model';

import { environment } from '../environments/environment';
import { getSessionId } from './helpers';
import { OrganizationsFilter } from './organizations-filter-model';

export interface FetchServicesResponseInterface {
    services: Service[];
}

export interface FetchOrganizationsResponseInterface {
    organizations: OrganizationsFilter[];
}

@Injectable()
export class FilterService extends RequestBase {

    public filter: Filter = null;

    public weekends = false;
    public weekdays = false;
    public days = false;

    public monday = false;
    public tuesday = false;
    public wednesday = false;
    public thursday = false;
    public friday = false;
    public saturday = false;
    public sunday = false;

    public taskType: string = '';

    constructor(
        private translate: TranslateService,
        public http: HttpClient,
        public authService: AuthService
    ) {
        super(http);
    }

    setFilter(filter: Filter, services?: Service[], organizations?: OrganizationsFilter[]): void {
        if (services) {
            filter.services = services.map(service => service.id).join(',');
        } else if (organizations) {
            filter.organizations = organizations.map(organization => organization.id).join(',')
        }

        this.filter = filter;
    }

    setTaskType(type: any) {
        if (Array.isArray(type) && type.length) {
           type.join(',');
        }
        this.taskType = type || '';
    }

    setFilterFields(fields): void {
        if (fields) {
            Object.keys(fields).forEach((field: string) => this[field] = fields[field]);
        }
    }

    fetchServices(): Observable<object> {
        const { sessionId, checksum } = this.authService;

        const apiUrl = environment.baseApiUrl.toString();
        const url = `${apiUrl}session/${sessionId}/services.json`;

        const httpOptions = {
            params: {
                cs: checksum,
                only_own_active_services: 'true'
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

    getTaskTypes(): Observable<object> {
        const { sessionId, checksum } = this.authService;

        const apiUrl = environment.baseApiUrl.toString();
        const url = `${apiUrl}session/${sessionId}/task_types.json`;

        const httpOptions = {
            params: {
                cs: checksum,
                locale: this.translate.store.defaultLang
            }
        };

        return this.http.get(url, httpOptions)
            .pipe(map(response => response))
            .pipe(catchError(this.handleError));
    }

    fetchOrganizations(): Observable<object> {
        const sessionId = getSessionId();

        const apiUrl = environment.baseApiUrl.toString();
        const url = `${apiUrl}session/${sessionId}/organizations.json`;

        const params = {
            with_public_tasks: 'true'
        };

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }

}
