import { PublicTask } from '../components/task/task.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { RequestBase } from './request-base';
import { map, catchError } from 'rxjs/operators';
import { getSessionId } from './helpers';
import { FilterService } from './filter-service';

export interface FetchPublicTasksResponseInterface {
    page: number;
    page_count: number;
    tasks: PublicTask[];
}

@Injectable()
export class PublicTasksService extends RequestBase {

    public taskCache: PublicTask[] = [];

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        public filterService: FilterService,
        private translate: TranslateService
    ) {
        super(http);
    }

    fetchPublicTasks(page: number) {
        let locale = this.translate.currentLang;
        if (!locale) {
            locale = this.authService.currentLanguage;
        }

        const { filter } = this.filterService;

        const sessionId = getSessionId();
        const apiUrl = environment.baseApiUrl.toString();

        const url =  apiUrl + `session/${sessionId}/public_tasks.json`;
        const resultsPerPage = 10;

        let params = {
            page: page.toString(),
            results_per_page: resultsPerPage.toString(),
            locale: locale
        };

        if (filter != null) {
            params = Object.assign(params, filter);
        }

        const httpOptions = {
            params
        };

        return this.http.get(url, httpOptions)
            .pipe(map(res => res))
            .pipe(catchError(this.handleError));
    }
}
