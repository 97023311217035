import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

/*
 * Converts a date to chat timestamp format.
 * Usage:
 *   value | chatTimestampFormat
 * Example:
 *   {{ '2017-06-14T11:49:35.299+03:00' | chatTimestampFormat}}
 *   formats to: 11:49 if today is 2017-06-14
 *   else 2017-06-14
*/
@Pipe({
    name: 'chatTimestampFormat'
})

export class ChatTimestampFormat implements PipeTransform {

    constructor(public translate: TranslateService) {
    }

    transform(value: string): string {

        let todayString;
        let yesterdayString;

        this.translate.get('chatPage.today').subscribe(
            data => todayString = data
        );

        this.translate.get('chatPage.yesterday').subscribe(
            data => yesterdayString = data
        );

        const date = moment(value);

        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const olderThanWeek = moment().subtract(7, 'days');

        if (moment().isSame(date, 'day')) {
            return todayString;
        }
        if (date < today && date > yesterday) {
            return yesterdayString;
        }
        if (date < yesterday && date > olderThanWeek) {
            return date.format('dddd');
        }
        if (date < olderThanWeek) {
            return date.format('L');
        }
   }
}
