import { Component, Input, ViewChild } from '@angular/core';
import { IonContent, NavParams, ModalController } from '@ionic/angular';
import { Events } from '../../providers/events';
import { Service } from '../../providers/service-model';
import { FilterService } from '../../providers/filter-service';
import { Filter } from '../../providers/filter-model';
import { Storage } from '@ionic/storage';
import { OrganizationsFilter } from '../../providers/organizations-filter-model';

export const DATES = ['weekends', 'weekdays', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const TIME_OF_DAY = ['morning', 'afternoon', 'evening'];

enum DateFilterBitmap {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 4,
    Thursday = 8,
    Friday = 16,
    Saturday = 32,
    Sunday = 64,
    Weekdays = 31,
    Weekends = 96
}

enum TimeOFDayFilter {
    morning = 11,
    afternoon = 12,
    evening = 13
}

const capitalize = (s) => {
    if (typeof s !== 'string') { return ''; }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export class FilterState {

    public weekends = false;
    public weekdays = false;
    public days = false;

    public monday = false;
    public tuesday = false;
    public wednesday = false;
    public thursday = false;
    public friday = false;
    public saturday = false;
    public sunday = false;

    public morning = false;
    public afternoon = false;
    public evening = false;

    toFilter() {
        let bitmap = 0;

        DATES.forEach((day: string) => {
            if (this[day]) {
                bitmap += DateFilterBitmap[capitalize(day)];
            }
        });

        if (bitmap > 127) {
            bitmap = 127;
        }

        if (DATES.every((day: string) => !this[day])) {
            bitmap = undefined;
        }

        const chosenTime = TIME_OF_DAY.find((time: string) => this[time]);

        const timeOfDay = TimeOFDayFilter[chosenTime];

        const filter = { weekdays: bitmap, time_of_day: timeOfDay } as Filter;

        // tslint:disable-next-line:no-shadowed-variable
        Object.keys(filter).forEach((key: string) => {
            if (!filter[key]) {
                delete filter[key];
            }
        });

        return filter;
    }

    public getFields() {
        const fields = {};

        DATES.forEach((date: string) => fields[date] = this[date]);

        return fields;
    }

    constructor(
        private storage: Storage,
        filterService: FilterService,
    ) {
        const { filter } = filterService;

        if (filter != null) {
            DATES.forEach((date: string) => {
                if (filterService[date] && date !== 'weekends' && date !== 'weekdays') {
                    this.days = true;
                }
                this[date] = filterService[date];
            });

            TIME_OF_DAY.forEach((time: string) => {
                if (filter.time_of_day === TimeOFDayFilter[time]) {
                    this[time] = true;
                }
            });
        } else {
            this.getData();
        }
    }

    async getData() {
        const filter = await this.storage.get('filterDays') as Filter;

        if (filter) {
            DATES.forEach((date: string) => this[date] = filter[date]);
        }
    }
}

@Component({
    selector: 'app-filter-modal',
    templateUrl: 'filter-modal.html',
    styleUrls: ['filter-modal.scss']
})

export class FilterModalComponent {

    @ViewChild(IonContent) content: IonContent;
    @Input() servicesProps: Service[];
    @Input() organizationsProps: OrganizationsFilter[];

    services: Service[] = this.navParams.get('servicesProps');
    organizations: OrganizationsFilter[] = this.navParams.get('organizationsProps');
    taskTypes = this.navParams.get('taskTypePops');

    public state: FilterState;
    taskType: string;

    constructor(
        private storage: Storage,
        private modalControl: ModalController,
        public navParams: NavParams,
        public filterService: FilterService,
        public events: Events) {

        this.state = new FilterState(storage, filterService);

        if (this.filterService.filter?.services) {
            const filteredServices = this.filterService.filter.services.split(',');

            this.services = this.services.map((service: Service) => {
                if (filteredServices.includes(service.id.toString())) {
                    service.enabled = true;
                }
                return service;
            });
        } else if (this.filterService.filter?.organizations) {
            const filteredOrganizations = this.filterService.filter.organizations.split(',');

            this.organizations = this.organizations.map((organization: OrganizationsFilter) => {
                if (filteredOrganizations.includes(organization.id.toString())) {
                    organization.enabled = true;
                }
                return organization;
            });
        }
        const { taskType } = this.filterService;
        if (taskType) {
            this.taskType = taskType;
        } else {
            this.getTaskType();
        }
    }

    async getTaskType() {
        this.taskType = await this.storage.get('filterTaskTypes') || [];
    }

    handleDaysToggleChange(value: string): void {

        if (value === 'weekends' || value === 'weekdays') {
            this.state.days = !this.state[value] ? false : this.state.days;
            DATES.forEach((date: string) => {
                if (date !== 'weekends' && date !== 'weekdays') {
                    this.state[date] = false;
                }
            });
        }

        if (value !== 'weekends' && value !== 'weekdays') {
            if (this.state.days) {
                DATES.forEach((date: string) => {
                    if (date !== 'weekends' && date !== 'weekdays') {
                        this.state[date] = false;
                    }
                });
            }

            this.state.weekdays = !this.state.days ? false : this.state.weekdays ;
            this.state.weekends = !this.state.days ? false : this.state.weekends;
        }
    }

    handleCheckboxChange(): void {
            this.state.days = false;
            this.state.weekdays = false;
            this.state.weekends = false;
    }

    handleTimeToggleChange(value: string) {
        TIME_OF_DAY.forEach((time: string) => this.state[time] = value !== time ? false : this.state[time]);
    }

    ionViewWillLeave(): void {
        if (this.services) {
            const filtered: Service[] = this.services.filter(service => service.enabled);
            this.filterService.setFilter(this.state.toFilter(), filtered);
        } else {
            const filteredOrganizations: OrganizationsFilter[] = this.organizations.filter(organization => organization.enabled);
            this.filterService.setFilter(this.state.toFilter(), null, filteredOrganizations);
        }

        this.storage.set('filterTaskTypes', this.taskType);

        this.storage.set('filterDays', this.state.getFields());

        this.filterService.setFilterFields(this.state.getFields());

        this.filterService.setTaskType(this.taskType);

        this.events.publish('modal:dismissed');
    }

    async dismissModal() {
        return await this.modalControl.dismiss();
    }

    taskTypeOptions(type) {
        this.taskType = type
    }

    resetSearchCriteria() {
        this.state.weekends = false;
        this.state.weekdays = false;
        this.state.days = false;
        this.state.monday = false;
        this.state.tuesday = false;
        this.state.wednesday = false;
        this.state.thursday = false;
        this.state.friday = false;
        this.state.saturday = false;
        this.state.sunday = false;
        this.state.morning = false;
        this.state.afternoon = false;
        this.state.evening = false;
        this.clearToggleSearch();
        this.taskType = '';
    }

    clearToggleSearch() {
        if (this.services) {
            this.services['enabled'] = this.services.map(el => {
                if (el.enabled === true) {
                    el.enabled = undefined
                }
            });
        } else {
            this.organizations['enabled'] = this.organizations.map(el => {
                if (el.enabled === true) {
                    el.enabled = undefined
                }
            });
        }
    }
}
