import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as OT from '@opentok/client';
import { PubNubAngular } from 'pubnub-angular2';
import { CryptoJsService } from './crypto-js-service';
import { Storage } from '@ionic/storage';

import { environment } from '../environments/environment';
import { AuthService } from './auth-service';
import { NavDataService } from './nav-data-service';

@Injectable()
export class OpentokService {
    session: OT.Session;
    token: string;
    participantName: string;
    public callerName: string;
    public callerPrivateChanel: string;
    public participantPrivateChannel: string;
    public isCaller: boolean = false;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private translate: TranslateService,
        private navDataService: NavDataService,
        private cryptoJsService: CryptoJsService,
        private storage: Storage,
        private pubnub: PubNubAngular
    ) {}

    getOT() {
        return OT;
    }

    async initSession({session, token, participantName, callerName, callerPrivateChanel, participantPrivateChannel}) {
        if (session && token) {
            this.session = this.getOT().initSession(environment.opentok_api_key, session);
            this.token = token;
            this.participantName = participantName;
            this.callerName = callerName;
            this.callerPrivateChanel = callerPrivateChanel;
            this.participantPrivateChannel = participantPrivateChannel;
            return Promise.resolve(this.session);
        } else {
            this.isCaller = true;
            const data: any = await this.fetchVideoCallConfig();
            const videoCallData = data.videocall;
            this.session = this.getOT().initSession(environment.opentok_api_key, videoCallData.session);
            const caller = videoCallData.participant_persons.find(el => {
                return el.id === this.authService.personId;
            });
            const participant = videoCallData.participant_persons.find(el => {
                return el.id !== this.authService.personId;
            });

            this.callerPrivateChanel = this.authService.decodeFromBase64(caller.private_channel);
            this.participantPrivateChannel = this.authService.decodeFromBase64(participant.private_channel);
            this.token = caller.token;
            this.participantName = participant.name;
            this.callerName = caller.name;

            this.pubnub.publish({
                channel: this.participantPrivateChannel,
                message: {
                    content: 'call:incoming',
                    callerName: this.callerName,
                    callerPrivateChanel: this.callerPrivateChanel,
                    session: videoCallData.session,
                    participantPrivateChannel: this.participantPrivateChannel,
                    participantToken: participant.token,
                    participantName: this.participantName
                }
                // Callback for testing
                // (status: any, response: any) => {
                // console.log('pubnub publish call:incoming status:', status);
                // console.log('pubnub publish call:incoming response:', response);
            });
            return Promise.resolve(this.session);
        }
    }

    connect() {
        return new Promise((resolve, reject) => {
            this.session.connect(this.token, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(this.session);
                }
            });
        });
    }

   async fetchVideoCallConfig() {
        const sessionId = this.authService.sessionId;
        const personId = this.authService.personId;
        const responsiblePersonId = this.navDataService.getNavParams()?.responsiblePersonId;
        const apiUrl = environment.baseApiUrl.toString();
        const url =  apiUrl + `session/${sessionId}/videocalls.json`;

        const dataRaw  = {
            cs: this.authService.checksum,
            locale: this.translate.store.defaultLang ,
            videocall: {
                caller_person_id: personId,
                participant_person_ids: [personId, responsiblePersonId],
                visibility_type_id: 1
            }
        };

        return this.http.post(url, dataRaw).toPromise();
    }
}
